import { useContext, useState } from "react";
import {
  Spacer,
  ChakraProvider,
  Heading,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import {
  //BrowserRouter as Router,// Mod20230702
  //Switch, // Mod20230702
  Routes,
  Route,
  Link,
  //useHistory,// Mod20230702
  useNavigate,
} from "react-router-dom";
import { SystemConst, SiteTitle } from "./SystemConst";
import { AuthContext, UserContext } from "./AuthContextProvider"; // Mod20230702
import { AnnouncementList } from "./AnnouncementList";
import { SnowPictureList } from "./SnowPicture";
import { WinterDrivingTip } from "./WinterDrivingTip";
import { UserList } from "./UserList";
import { AnnouncemenHistoryList } from "./history/AnnouncementHistoryList";
import { SnowPictureHistoryList } from "./history/SnowPictureHistoryList";
import { WinterDrivingTipHistoryList } from "./history/WinterDrivingTipHistoryList";
import { ContentsTitleHistoryList } from "./history/ContentsTitleHistoryList";

import { SystemManagement } from "./SystemManagement";

type menuItemStats = {
  announcement: string;
  snow: string;
  drivingTip: string;
  history: string;
  userList: string;
  systemmanagement: string;
};

export function Home() {
  const [, setAuth] = useContext(AuthContext); // Add20230702
  const [user, setUser] = useContext(UserContext);
  if (!user) {
    throw Error("user is not null");
  }

  //const history = useHistory(); // Mod20230702
  const history = useNavigate();
  // アカウントが Admin権限（WNI）かどうか
  const isAdminDepartment: boolean =
    user.department === SystemConst.AdminDepartment ? true : false;

  const foucusCss =
    "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)";
  const [btnFocus, setBtnFocus] = useState<menuItemStats>({
    announcement: foucusCss,
    snow: "none",
    drivingTip: "none",
    history: "none",
    userList: "none",
    systemmanagement: "none",
  });
  const currentMenuShowStats = (pname: string) => {
    //console.info(pname)
    const res: menuItemStats = {
      announcement: "none",
      snow: "none",
      drivingTip: "none",
      history: "none",
      userList: "none",
      systemmanagement: "none",
    };

    switch (pname) {
      case "announcement":
        res.announcement = foucusCss;
        break;
      case "snow":
        res.snow = foucusCss;
        break;
      case "drivingTip":
        res.drivingTip = foucusCss;
        break;
      case "history":
        res.history = foucusCss;
        break;
      case "userList":
        res.userList = foucusCss;
        break;
      case "systemmanagement":
        res.systemmanagement = foucusCss;
        break;
      default:
        break;
    }
    setBtnFocus(res);
  };

  return (
    <ChakraProvider>
      <Box
        w={"100%"}
        minHeight="60px"
        display={{ md: "flex" }}
        bg="gray.600"
        mb={4}
        alignItems="center"
        px={4}
      >
        <Heading
          color="white"
          minWidth="max-content"
          fontSize={{ sm: "sm", md: "md" }}
        >
          {SiteTitle(process.env.REACT_APP_ENVIRONMENT)}
        </Heading>
        <Box w="100%" display="flex" alignItems="center">
          <Button
            as={Link}
            to="/announcement"
            size="sm"
            fontSize={{ sm: "sm", md: "md" }}
            _hover={{ bg: "gray.100", color: "blue.400" }}
            variant="ghost"
            color="gray.50"
            boxShadow={btnFocus.announcement}
            onClick={() => {
              currentMenuShowStats("announcement");
            }}
          >
            {SystemConst.AnnouncementTitle}
          </Button>
          {isAdminDepartment ? (
            <>
              <Button
                color="gray.50"
                variant="ghost"
                as={Link}
                to="/snow"
                size="sm"
                fontSize={{ sm: "sm", md: "md" }}
                _hover={{ bg: "gray.100", color: "blue.400" }}
                boxShadow={btnFocus.snow}
                onClick={() => {
                  currentMenuShowStats("snow");
                }}
              >
                {SystemConst.SnowPictureTitle}
              </Button>
              <Button
                color="gray.50"
                variant="ghost"
                as={Link}
                to="/drivingTip"
                size="sm"
                fontSize={{ sm: "sm", md: "md" }}
                _hover={{ bg: "gray.100", color: "blue.400" }}
                boxShadow={btnFocus.drivingTip}
                onClick={() => {
                  currentMenuShowStats("drivingTip");
                }}
              >
                {SystemConst.WinterDrivingTipTitle}
              </Button>
            </>
          ) : (
            <></>
          )}
          <Menu>
            <MenuButton
              px={2}
              py={1}
              transition="all 0.2s"
              borderRadius="md"
              _hover={{ bg: "gray.100", color: "blue.400" }}
              _expanded={{ bg: "blue.100" }}
              fontWeight="extrabold"
              color="white"
              fontSize={{ sm: "sm", md: "1.1em" }}
              //_focus={{ boxShadow: "outline" }}
              boxShadow={btnFocus.history}
              onClick={() => {
                currentMenuShowStats("history");
              }}
            >
              履歴
            </MenuButton>
            <Box zIndex="5">
              <MenuList>
                <MenuItem as={Link} to="/announcement-history">
                  {SystemConst.AnnouncementTitle}
                </MenuItem>
                {isAdminDepartment ? (
                  <>
                    <MenuItem as={Link} to="/snow-picture-history">
                      {" "}
                      {SystemConst.SnowPictureTitle}
                    </MenuItem>
                    <MenuItem as={Link} to="/winter-driving-tip-history">
                      {" "}
                      {SystemConst.WinterDrivingTipTitle}
                    </MenuItem>
                    <MenuItem as={Link} to="/contents-title-history">
                      {" "}
                      {SystemConst.ContentsTitleTitle}
                    </MenuItem>
                  </>
                ) : (
                  <></>
                )}
              </MenuList>
            </Box>
          </Menu>
          <Button
            color="gray.50"
            variant="ghost"
            as={Link}
            to="/userList"
            size="sm"
            fontSize={{ sm: "sm", md: "md" }}
            _hover={{ bg: "gray.100", color: "blue.400" }}
            boxShadow={btnFocus.userList}
            onClick={() => {
              currentMenuShowStats("userList");
            }}
          >
            {SystemConst.UserTitle}
          </Button>
          <Spacer />

          {(isAdminDepartment && user.role === SystemConst.UserTypeAdmin) ? (
            <Button
              color="gray.50"
              variant="outline"
              as={Link}
              to="/system-management"
              size="sm"
              fontSize={{ sm: "sm", md: "md" }}
              mr={"10px"}
              _hover={{ bg: "gray.100", color: "blue.400" }}
              boxShadow={btnFocus.systemmanagement}
              onClick={() => {
                currentMenuShowStats("systemmanagement");
              }}
            >
              {SystemConst.SystemManagementTitle}
            </Button>
          ) : (
            <></>
          )}

          <Tooltip hasArrow label={user.person} bg="blue.600">
            <Button
              size="sm"
              fontSize={{ sm: "sm", md: "md" }}
              onClick={async () => {
                if (window.confirm("Sign Out しますか？")) {
                  //console.info("aaaa");
                  setUser(undefined);
                  setAuth(false); // Add2023002
                  //history.push("/login"); // Mod2023002
                  history("/login");
                }
              }}
              colorScheme="teal"
              variant="outline"
            >
              Sign Out
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Box overflow="auto">
        <Routes>
          <Route path="/announcement" element={<AnnouncementList />} />
          <Route path="/snow" element={<SnowPictureList />} />
          <Route path="/drivingTip" element={<WinterDrivingTip />} />
          <Route
            path="/announcement-history"
            element={<AnnouncemenHistoryList dep={String(user.department)} />}
          />
          <Route
            path="/snow-picture-history"
            element={<SnowPictureHistoryList dep={String(user.department)} />}
          />
          <Route
            path="/winter-driving-tip-history"
            element={
              <WinterDrivingTipHistoryList dep={String(user.department)} />
            }
          />
          <Route
            path="/contents-title-history"
            element={<ContentsTitleHistoryList dep={String(user.department)} />}
          />
          <Route path="/userList" element={<UserList />} />

          <Route path="/system-management" element={<SystemManagement />} />

          <Route path="/" element={<AnnouncementList />} />
        </Routes>
      </Box>
    </ChakraProvider>
  );
}
