import { useRef } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

type myAlertDialogProps = {
  isOpen: boolean;
  handleOnClose: () => void;
  validMessage: string;
};

export const MyAlertDialog = (props: myAlertDialogProps) => {
  const onAlertClose = () => props.handleOnClose();
  const cancelAlertRef = useRef(null);

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      isOpen={props.isOpen}
      leastDestructiveRef={cancelAlertRef}
      onClose={onAlertClose}
      isCentered
      blockScrollOnMount={false}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          内容の確認
        </AlertDialogHeader>
        <AlertDialogBody color="red.500">{props.validMessage}</AlertDialogBody>
        <AlertDialogFooter>
          <Button colorScheme="blue" onClick={onAlertClose} ml={3}>
            OK
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
