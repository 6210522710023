import { useState, useEffect } from "react";
import {
  Checkbox,
  Button,
  SimpleGrid,
  Text,
  Flex,
  Spacer,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { IBaseInfo, IBaseItem } from "../SystemConst";

type myInfoShareCheckBoxProps = {
  area: string;
  //shareStatus: boolean;
  baseList?: IBaseInfo[];
  handleOnChange: (res: string[]) => void;
  handleOnClose: () => void;
};

export const MyInfoShareCheckBox: React.FunctionComponent<
  myInfoShareCheckBoxProps
> = (props) => {
  const initCheckStatus = (datas?: IBaseInfo[]) => {
    //console.info("initCheckStatus")
    const branchBaseItemAry: any[] = [];
    const IndeterminateAry: boolean[] = [];
    const branchAllCheckedAry: boolean[] = [];
    if (datas) {
      for (let i = 0; i < datas.length; i++) {
        const tmpBaseItems: boolean[] = [];
        datas[i].baseList.forEach((value) => {
          tmpBaseItems.push(value.status);
        });

        branchBaseItemAry[i] = tmpBaseItems;
        branchAllCheckedAry[i] = tmpBaseItems.every(Boolean);
        IndeterminateAry[i] =
          tmpBaseItems.some(Boolean) && !branchAllCheckedAry[i];
      }
    }

    return {
      branchAllCheckedAry: branchAllCheckedAry,
      IndeterminateAry: IndeterminateAry,
      branchBaseItemAry: branchBaseItemAry,
    };
  };
  const updateCheckStatus = (datas: any[]) => {
    const branchBaseItemAry: any[] = [];
    const IndeterminateAry: boolean[] = [];
    const branchAllCheckedAry: boolean[] = [];
    if (datas) {
      for (let i = 0; i < datas.length; i++) {
        const tmpBaseItems: boolean[] = [];
        datas[i].forEach((value: boolean) => {
          tmpBaseItems.push(value);
        });

        branchBaseItemAry[i] = tmpBaseItems;
        branchAllCheckedAry[i] = tmpBaseItems.every(Boolean);
        IndeterminateAry[i] =
          tmpBaseItems.some(Boolean) && !branchAllCheckedAry[i];
      }
    }

    return {
      branchAllCheckedAry: branchAllCheckedAry,
      IndeterminateAry: IndeterminateAry,
      branchBaseItemAry: branchBaseItemAry,
    };
  };

  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [allItemCheckd, setAllItemCheckd] = useState<boolean[]>([]);
  const [indeterminate, setIndeterminate] = useState<boolean[]>([]);

  // チェックボックス（Branch）：ON/OFF時の処理
  const updateBranchChecked = (branchIdx: number, status: boolean) => {
    //console.info(branchIdx +":"+ status)
    let copyAry = JSON.parse(JSON.stringify(checkedItems));
    for (let i = 0; i < checkedItems[branchIdx].length; i++) {
      copyAry[branchIdx][i] = status;
    }

    const updateSettings = updateCheckStatus(copyAry);
    setCheckedItems(updateSettings.branchBaseItemAry);
    setAllItemCheckd(updateSettings.branchAllCheckedAry);
    setIndeterminate(updateSettings.IndeterminateAry);
  };
  // チェックボックス（Base）：ON/OFF時の処理
  const updateBaseChecked = (
    branchIdx: number,
    baseIdx: number,
    status: boolean,
  ) => {
    let copyAry = JSON.parse(JSON.stringify(checkedItems));
    copyAry[branchIdx][baseIdx] = status;

    const updateSettings = updateCheckStatus(copyAry);
    setCheckedItems(updateSettings.branchBaseItemAry);
    setAllItemCheckd(updateSettings.branchAllCheckedAry);
    setIndeterminate(updateSettings.IndeterminateAry);
  };

  // チェックボックス（Base）の作成
  const createBaseCheckBoxs = (bases: IBaseItem[], parentIdx: number) => {
    return (
      <SimpleGrid pl={0} mt={1} columns={3} spacing={1}>
        {bases.map((data, index) => (
          <Checkbox
            key={index}
            isChecked={checkedItems[parentIdx][index]}
            onChange={(e) =>
              updateBaseChecked(parentIdx, index, e.target.checked)
            }
            value={data.code}
          >
            {data.name}
          </Checkbox>
        ))}
      </SimpleGrid>
    );
  };
  // チェックボックス（Branch）の作成
  const createBranchCheckBox = (
    name: string,
    bases: IBaseItem[],
    index: number,
  ) => {
    if (checkedItems.length === 0) {
      return <div key={index}></div>;
    }

    return (
      <AccordionItem key={index}>
        <Box mb={2} p={0}>
          <Flex>
            <Checkbox
              isChecked={allItemCheckd[index]}
              isIndeterminate={indeterminate[index]}
              onChange={(e) => updateBranchChecked(index, e.target.checked)}
            >
              {name}
            </Checkbox>
            <Spacer />
            <Box w={"50px"} textAlign={"right"} h={"20px"} p={0} m={0}>
              <AccordionButton
                _focus={{ boxShadow: "none" }}
                //visibility={"hidden"}
              >
                <AccordionIcon />
              </AccordionButton>
            </Box>
          </Flex>
          <AccordionPanel pb={4}>
            {createBaseCheckBoxs(bases, index)}
          </AccordionPanel>
        </Box>
      </AccordionItem>
    );
  };

  useEffect(() => {
    //console.info("useEffect")
    const initSettings = initCheckStatus(props.baseList);
    setCheckedItems(initSettings.branchBaseItemAry);
    setAllItemCheckd(initSettings.branchAllCheckedAry);
    setIndeterminate(initSettings.IndeterminateAry);
  }, [props.baseList]);

  // 親のModalをクローズする（更新無し）
  const checkBoxPopUpPanelClose = () => {
    props.handleOnClose();
  };

  const checkBoxUpdateClose = () => {
    // チェックONの拠点Code配列を返す
    const resultCheckedAry: string[] = [];
    if (props.baseList) {
      for (let i = 0; i < checkedItems.length; i++) {
        for (let t = 0; t < checkedItems[i].length; t++) {
          if (checkedItems[i][t] === true) {
            resultCheckedAry.push(props.baseList[i].baseList[t].code);
          }
        }
      }
    }
    //console.info(resultCheckedAry)
    props.handleOnChange(resultCheckedAry);
  };

  return (
    <>
      <Box
        p={2}
        bgColor={"gray.50"}
        h={"250px"}
        overflow={"auto"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "16px",
            borderRadius: "8px",
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
          },
        }}
      >
        <Accordion
          allowMultiple
          //defaultIndex={[0]}  // 最初から展開しておきたい所のIndexを指定
        >
          {props.baseList && props.baseList.length > 0
            ? props.baseList.map((data, index) =>
                createBranchCheckBox(data.branchName, data.baseList, index),
              )
            : "連携情報を取得できませんでした。"}
        </Accordion>
      </Box>
      <Text mt={3} fontSize="xs">
        （※ チェックした場合は「URL」の指定が必須です）
      </Text>
      <Flex alignItems="center">
        <Spacer />
        {props.baseList && props.baseList.length > 0 ? (
          <Button colorScheme="blue" mr={3} onClick={checkBoxUpdateClose}>
            OK
          </Button>
        ) : (
          <></>
        )}
        <Button variant="ghost" onClick={checkBoxPopUpPanelClose}>
          Cancel
        </Button>
      </Flex>
    </>
  );
};
