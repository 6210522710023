import * as React from "react";
import { Button, Box, Center } from "@chakra-ui/react";

interface Props {
  curr: number; // 現在のページナンバー
  sum: number; // 総件数（データ数）
  per: number; // １ページに表示する件数
  onChange: (e: { page: number }) => void;
}

export const MyPagination: React.FC<Props> = (props) => {
  const isFirstRender = React.useRef(true); // 初回レンダリングかどうかを判定するための変数
  const [currentPage, setPage] = React.useState(props.curr); // 現在のページ番号
  const [preBtnStat, setPreBtnStat] = React.useState(true); // 前ページボタン：disableステータス
  const [fwdBtnStat, setFwdBtnStat] = React.useState(true); // 次ページボタン：disableステータス
  const [fstBtnStat, setFstBtnStat] = React.useState(true); // 1ページ目ボタン：disableステータス
  const [lstBtnStat, setLstBtnStat] = React.useState(true); // 最終ページボタン：disableステータス

  // 総ページ数
  const totalPage: number = Math.ceil(props.sum / props.per);

  React.useEffect(() => {
    setPreBtnStat(currentPage === 1 ? true : false);
    setFwdBtnStat(currentPage === totalPage ? true : false);
    setFstBtnStat(currentPage === 1 ? true : false);
    setLstBtnStat(currentPage === totalPage ? true : false);
    // 初回レンダリング時はスキップし、変数を更新する
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    // 親コンポーネントにpage番号を渡す
    props.onChange({ page: currentPage });
    // eslint-disable-next-line
  }, [currentPage]);

  // 「|<」がクリックされたときの処理
  const handleBackFst = (): void => {
    setPage(1);
  };
  // 「>|」がクリックされたときの処理
  const handleForwardLst = (): void => {
    setPage(totalPage);
  };

  // 「<」がクリックされたときの処理
  const handleBack = (): void => {
    if (currentPage === 1) {
      setFwdBtnStat(currentPage - 1 === totalPage ? true : false);
      return;
    }
    setPage(currentPage - 1);
  };

  // 「>」がクリックされたときの処理
  const handleForward = (): void => {
    if (currentPage === totalPage) {
      setPreBtnStat(false);
      return;
    }
    setPage(currentPage + 1);
  };

  // ページ番号を直接クリックされたときの処理（未実装）
  //const handleMove = (page: number): void => {
  //  setPage(page);
  //};

  return (
    <div>
      {/* ページ番号が0（= アイテムが0個）のときは何も描画しない */}
      {totalPage !== 0 && (
        <>
          <Center>
          <Button
              isDisabled={fstBtnStat}
              size="sm"
              onClick={() => {
                handleBackFst();
              }}
            >
              ｜＜
            </Button>
            <Button
              isDisabled={preBtnStat}
              size="sm"
              onClick={() => {
                handleBack();
              }}
            >
              ＜
            </Button>
            <Box ml={5} mr={5}>
              {currentPage} / {totalPage}
            </Box>
            <Button
              isDisabled={fwdBtnStat}
              size="sm"
              onClick={() => {
                handleForward();
              }}
            >
              ＞
            </Button>
            <Button
              isDisabled={lstBtnStat}
              size="sm"
              onClick={() => {
                handleForwardLst();
              }}
            >
              ＞｜
            </Button>
          </Center>
        </>
      )}
    </div>
  );
};
