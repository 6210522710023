import React, { useContext } from "react";
import { AuthContext } from "./AuthContextProvider";
import Login from "./Login";
import { Home } from "./Home";

const AuthenticatedGuard: React.FC<{}> = () => {
  // 以下で他のコンポーネントから共通の認証情報を取得できる
  const [auth] = useContext(AuthContext);

  if (auth) {
    //console.info(" ログインしてる");
    return <Home />;
  } else {
    //console.log(` ログインしていない`);
    return <Login />;
  }
};

export default AuthenticatedGuard;
