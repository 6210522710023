/** 定数定義 */
export const SystemConst = {
  /** アプリケーション名 **/
  applicationName: "高速道路影響情報管理ツール",
  /** 'お知らせ' */
  AnnouncementTitle: "お知らせ",
  /** '大雪(雨)画像' */
  SnowPictureTitle: "画像",
  /** '雪道運転(防災豆)知識' */
  WinterDrivingTipTitle: "Tips",
  /** 'コンテンツのタイトル' */
  ContentsTitleTitle: "タイトル",
  /** 'ユーザ管理' */
  UserTitle: "ユーザ管理",

  /** 'システム管理' */
  SystemManagementTitle: "System Management",

  /** 'ADMIN' */
  UserTypeAdmin: "ADMIN",
  /** 'USER' */
  UserTypeUser: "USER",

  /** 'WNI' */
  AdminDepartment: "WNI",
  /** 'EAST' */
  DepartmentEAST: "EAST",
  /** 'CENTER' */
  DepartmentCENTER: "CENTER",
  /** 'WEST' */
  DepartmentWEST: "WEST",
  /** 'TOKYO' */
  DepartmentTOKYO: "TOKYO",
  /** 'NAGOYA' */
  DepartmentNAGOYA: "NAGOYA",

  /** '東日本' */
  AreaNameEAST: "東日本",
  /** '中日本' */
  AreaNameCENTER: "中日本",
  /** '西日本' */
  AreaNameWEST: "西日本",
  /** '首都高速' */
  AreaNameTOKYO: "首都高速",
  /** '名古屋高速' */
  AreaNameNAGOYA: "名古屋高速",
};

/** 実行環境名:
 *
 * 開発環境, 検証環境, 運用環境, local
 */
export const EnvironmentNames: { [key: string]: string } = {
  dev: "開発環境",
  stg: "検証環境",
  prod: "運用環境",
  local: "local",
};
/**
 *
 * 西日本、中日本、東日本、首都高速、名古屋高速、WNI
 */
export const AreaTagetNames: { [key: string]: string } = {
  WEST: SystemConst.AreaNameWEST,
  CENTER: SystemConst.AreaNameCENTER,
  EAST: SystemConst.AreaNameEAST,
  TOKYO: SystemConst.AreaNameTOKYO,
  NAGOYA: SystemConst.AreaNameNAGOYA,
  WNI: SystemConst.AdminDepartment,
};
/**エリア名
 *
 * 西日本、中日本、東日本、首都高速、名古屋高速
 */
export const AreaHash: { [key: string]: string } = {
  WEST: SystemConst.AreaNameWEST,
  CENTER: SystemConst.AreaNameCENTER,
  EAST: SystemConst.AreaNameEAST,
  TOKYO: SystemConst.AreaNameTOKYO,
  NAGOYA: SystemConst.AreaNameNAGOYA,
};
/**
 *
 */
export const AreaOptionAry: { name: string; value: string }[] = [
  { name: SystemConst.AreaNameWEST, value: SystemConst.DepartmentWEST },
  { name: SystemConst.AreaNameCENTER, value: SystemConst.DepartmentCENTER },
  { name: SystemConst.AreaNameEAST, value: SystemConst.DepartmentEAST },
  { name: SystemConst.AreaNameTOKYO, value: SystemConst.DepartmentTOKYO },
  { name: SystemConst.AreaNameNAGOYA, value: SystemConst.DepartmentNAGOYA },
];
/**
 *
 */
export const TimeHash: { [key: string]: string } = {
  T12: "12時間",
  T24: "24時間",
  T72: "72時間",
};

/**
 * Schema:ContentsTitleKey
 */
export const ContentsTitleKeyHash: { [key: string]: string } = {
  INFO: "INFO",
  IMG: "IMG",
  TIPS: "TIPS",
};
export const ContentsTitleNameHash: { [key: string]: string } = {
  INFO: "お知らせ",
  IMG: "画像",
  TIPS: "TIPS",
};

/**
 *
 */
export const DepartmentOptionAry: { name: string; value: string }[] = [
  { name: "WNI", value: SystemConst.AdminDepartment },
  { name: SystemConst.AreaNameWEST, value: SystemConst.DepartmentWEST },
  { name: SystemConst.AreaNameCENTER, value: SystemConst.DepartmentCENTER },
  { name: SystemConst.AreaNameEAST, value: SystemConst.DepartmentEAST },
  { name: SystemConst.AreaNameTOKYO, value: SystemConst.DepartmentTOKYO },
  { name: SystemConst.AreaNameNAGOYA, value: SystemConst.DepartmentNAGOYA },
];
/**
 *
 */
export const RoleOptionAry: { name: string; value: string }[] = [
  { name: SystemConst.UserTypeUser, value: SystemConst.UserTypeUser },
  { name: SystemConst.UserTypeAdmin, value: SystemConst.UserTypeAdmin },
];
/**
 *
 */
export const StatusOptionAry: { name: string; value: string }[] = [
  { name: "ACTIVE", value: "ACTIVE" },
  { name: "INACTIVE", value: "INACTIVE" },
];
/**
 * 所属（会社）
 */
export const Departments = {
  EAST: SystemConst.DepartmentEAST,
  CENTER: SystemConst.DepartmentCENTER,
  WEST: SystemConst.DepartmentWEST,
  TOKYO: SystemConst.DepartmentTOKYO,
  NAGOYA: SystemConst.DepartmentNAGOYA,
  WNI: SystemConst.AdminDepartment,
} as const;
export type Department = (typeof Departments)[keyof typeof Departments];

export const DwCustomerCodeHash: { [key: string]: string } = {
  EAST: "enx",
  CENTER: "cnx",
  WEST: "wnx",
  TOKYO: "mew",
  NAGOYA: "new",
};

/**
 * 操作
 */
export const Operations = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  DELETE: "DELETE",
} as const;
export type Operation = (typeof Operations)[keyof typeof Operations];

/**
 * ユーザ権限
 */
export const UserTypes = {
  ADMIN: SystemConst.UserTypeAdmin,
  USER: SystemConst.UserTypeUser,
} as const;
export type UserType = (typeof UserTypes)[keyof typeof UserTypes];

export const UserStatuses = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
} as const;
export type UserStatus = (typeof UserStatuses)[keyof typeof UserStatuses];

/////////////////////////////
// Interfaces
/////////////////////////////
export interface IBaseItem {
  code: string;
  name: string;
  status: boolean;
}
export interface IBaseInfo {
  branchCode: string;
  branchName: string;
  baseList: IBaseItem[];
}

export interface IAnnouncementBody {
  title: string;
  url: string;
  area: string;
  shareStatus: number;
  shares?: { code: string }[]; // 連携対象の拠点コードのオブジェクト配列
  event_area: number; // add 20240510 NEXCO３社連携
  importance: number; // add 20240510 NEXCO３社連携
  fromDate: Date;
  toDate?: Date;
  createdAt?: Date;
}
export interface IAnnouncement extends IAnnouncementBody {
  id: number;
}

export interface ISnowPictureBody {
  time: "T12" | "T24" | "T72";
  content: string;
  fromDate: Date;
  toDate?: Date;
}
export interface ISnowPicture extends ISnowPictureBody {
  id: number;
}

export interface IWinterDrivingTipBody {
  title: string;
  url: string;
  order: number;
  thumbnail: string;
  fromDate: Date;
  toDate?: Date;
}
export interface IWinterDrivingTip extends IWinterDrivingTipBody {
  id: number;
}

export interface IAnnouncementWithTitle {
  title: string;
  list: IAnnouncement[];
}

export interface ISnowPictureWithTitle {
  title: string;
  list: ISnowPicture[];
}

export interface IWinterDrivingTipWithTitle {
  title: string;
  list: IWinterDrivingTip[];
}

export interface IInputContentsTitle {
  titleText: string;
  contentsTitle: string;
  inputMaxLen: number;
  func_setContentsTitle: any;
  func_updateTitle: any;
}

/**
 * Announcement 登録 Modal Form のパラメータ
 */
export interface IAnnouncementModalRegForm {
  props: IAnnouncement;
  isOpenFlag: boolean;
  userDepartment: string;
  funcRegAction: any;
  funcClose: any;
  funcRefresh: any;
}
/**
 * SnowPicture 登録 Modal Form のパラメータ
 */
export interface ISnowPictureModalRegForm {
  props: ISnowPicture;
  isMulti: boolean;
  isOpenFlag: boolean;
  funcUploadImg: any;
  funcClose: any;
  funcRefresh: any;
}
/**
 * WinterDrivingTip 登録 Modal Form のパラメータ
 */
export interface IWinterDrivingTipModalRegForm {
  props: IWinterDrivingTip;
  isOpenFlag: boolean;
  funcUploadImg: any;
  funcClose: any;
  funcRefresh: any;
}

/**
 * 履歴ページInterface
 */
export interface IHistoryInterface {
  dep: string;
}

export interface IAnnouncementHistory {
  id: number;
  title: string;
  url: string;
  area: "WEST" | "CENTER" | "EAST" | "TOKYO" | "NAGOYA";
  fromDate?: Date;
  toDate?: Date;
  operation: string;
  department: string;
  person: string;
  createdAt: Date;

  shareStatus: number;
  event_area: number; // add 20240510 NEXCO３社連携
  importance: number; // add 20240510 NEXCO３社連携
}

export interface ISnowPictureHistory {
  id: number;
  content: string;
  time: "T12" | "T24" | "T72";
  fromDate?: Date;
  toDate?: Date;
  operation: string;
  department: string;
  person: string;
  createdAt: Date;
}

export interface IWinterDrivingTipHistory {
  id: number;
  title: string;
  url: string;
  order: number;
  thumbnail: string;
  fromDate?: Date;
  toDate?: Date;
  operation: string;
  department: string;
  person: string;
  createdAt: Date;
}
export interface IContentsTitleHistory {
  id: number;
  title: string;
  idType: "INFO" | "IMG" | "TIPS";
  operation: string;
  department: string;
  person: string;
  createdAt: Date;
}

export interface IImpEventAreaBaseInfo {
  eventAreaMask: number;
  importance: number;
}

/////////////////////////////
// Functions
/////////////////////////////
//@returns YYYY/MM/DD hh:mm:00
export const nowDateYMDHM_Szero = () => {
  const zDate = new Date();
  const fDate =
    zDate.getFullYear() +
    "/" +
    ("0" + (zDate.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + zDate.getDate()).slice(-2) +
    " " +
    ("0" + zDate.getHours()).slice(-2) +
    ":" +
    ("0" + zDate.getMinutes()).slice(-2) +
    ":00";
  return new Date(fDate);
};
/**
 * 日付フォーマット変換
 * @param dt "1999-12-31T23:59:59+0900" [ISO 8601（拡張形式）]
 * @returns YYYY/MM/DD hh:mm:ss
 */
export const FormatDate = (dt: Date) => {
  // 文字列変換するので、ゼロ埋めしておく（ソート対策）
  const zDate = new Date(dt);
  const fDate =
    zDate.getFullYear() +
    "/" +
    ("0" + (zDate.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + zDate.getDate()).slice(-2) +
    " " +
    ("0" + zDate.getHours()).slice(-2) +
    ":" +
    ("0" + zDate.getMinutes()).slice(-2) +
    ":" +
    ("0" + zDate.getSeconds()).slice(-2);

  return fDate;
};
export const FormatDateYMDHM = (dt: Date) => {
  // 文字列変換するので、ゼロ埋めしておく（ソート対策）
  const zDate = new Date(dt);
  const fDate =
    zDate.getFullYear() +
    "/" +
    ("0" + (zDate.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + zDate.getDate()).slice(-2) +
    " " +
    ("0" + zDate.getHours()).slice(-2) +
    ":" +
    ("0" + zDate.getMinutes()).slice(-2);

  return fDate;
};

/**
 * DB: UTCで登録されている
 * ex. DB値:        2021-11-01 13:22:33.112
 *     pa.fromDate: 2021-11-01T13:22:33.112Z
 * => JSTで出力する 2021-11-01 22:22:33
 *
 * @param fromDate
 * @param toDate
 * @returns
 */
export const periodFormatText = (fromDate: Date, toDate?: Date) => {
  const res = {
    isValid: true,
    colorState: "black.400", // 表示対象（期間内）の色（黒: Default）
    fromDateFormat: "",
    toDateFormat: "",
  };

  const nowDate = new Date();
  const fDate = new Date(fromDate);
  res.fromDateFormat = fromDate ? FormatDateYMDHM(fromDate) : "";
  res.toDateFormat = toDate ? FormatDateYMDHM(toDate) : "";
  if (fDate > nowDate) {
    // 掲載開始日になっていない
    res.colorState = "blue.400";
    res.isValid = false;
  } else {
    // 掲載開始日を経過している
    if (toDate) {
      // 終了日の指定あり
      const tDate = new Date(toDate);
      if (tDate < nowDate) {
        // 終了日を過ぎている
        //        res.colorState = "red.400";
        res.colorState = "gray.400";
        res.isValid = false;
      }
    }
  }
  return res;
};

// 20240425 add
// in:  "2024-04-24T06:00:00.000Z"
// out: "20240424060000"
export const convertDateUTC = (dt: string | number | Date) => {
  const now = new Date(dt);
  const utcStr =
    now.getUTCFullYear() +
    ("0" + (now.getUTCMonth() + 1)).slice(-2) +
    ("0" + now.getUTCDate()).slice(-2) +
    ("0" + now.getUTCHours()).slice(-2) +
    ("0" + now.getUTCMinutes()).slice(-2) +
    ("0" + now.getSeconds()).slice(-2);
  return utcStr;
};

export const isValidPeriod = (fromDate: Date, toDate?: Date) => {
  const nowDate = new Date();
  const fDate = new Date(fromDate);
  let res = 0;
  if (fDate > nowDate) {
    // 掲載開始日になっていない
    res = -1;
  } else {
    // 掲載開始日を経過している
    res = 1;
    if (toDate) {
      // 終了日の指定あり
      const tDate = new Date(toDate);
      if (tDate < nowDate) {
        // 終了日を過ぎている
        res = 0;
      }
    }
  }
  return res;
};

export const SiteTitle = (environment: string | undefined) => {
  const environmentKey: string = environment ? environment : "local";
  return `${SystemConst.applicationName} (${EnvironmentNames[environmentKey]})`;
};

/**
 * 空文字以外が１つ以上あるかチェック
 *
 * @param txt:string
 * @returns OK:true（空文字以外が１つ以上ある）
 */
export const TextEmptyCheck = (txt: string) => {
  const tmp = txt;
  if (txt && tmp.trim().length > 0) {
    return true;
  }
  return false;
};

/**
 * undefinedの場合は空文字を返す
 *
 * @param dt:string|undefined
 * @returns "" | dt
 */
export const returnStringValue = (dt: string | undefined) => {
  if (!dt) {
    return "";
  } else {
    return dt;
  }
};

const baselMaskValue = 1;
export const NexcoBranchEventAreaInfo: {
  [key: string]: { lbl: string; mask: number }[];
} = {
  // SystemConst.AreaNameWEST
  EAST: [
    { lbl: "北海道", mask: baselMaskValue },
    { lbl: "東北", mask: baselMaskValue << 1 }, // (2)
    { lbl: "関東", mask: baselMaskValue << 2 }, // (4)
    { lbl: "新潟", mask: baselMaskValue << 3 }, // (8)
  ],
  // SystemConst.AreaNameCENTER
  CENTER: [
    { lbl: "東京", mask: baselMaskValue << 4 }, // (16)
    { lbl: "八王子", mask: baselMaskValue << 5 }, // (32)
    { lbl: "名古屋", mask: baselMaskValue << 6 }, // (64)
    { lbl: "金沢", mask: baselMaskValue << 7 }, // (128)
  ],
  // SystemConst.AreaNameEAST
  WEST: [
    { lbl: "関西", mask: baselMaskValue << 8 }, // (256)
    { lbl: "中国", mask: baselMaskValue << 9 }, // (512)
    { lbl: "四国", mask: baselMaskValue << 10 }, // (1024)
    { lbl: "九州", mask: baselMaskValue << 11 }, // (2048)
  ],
};

// NEXCO３社連携機能 ON/OFF（各社足並み揃うまでの機能非表示制御：暫定処置⇒false）
export const isEventAreaShare = false;
// NEXCO３社連携対象会社
export const EventAreaShareDepartments = [
  SystemConst.DepartmentEAST,
  SystemConst.DepartmentCENTER,
  SystemConst.DepartmentWEST,
];
// NEXCO3社連携対象か否かを返す 20240510 add
export const isEventAreaDepartment = (area: string) => {
  return EventAreaShareDepartments.includes(area);
};
