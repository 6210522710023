import React from "react";
import { IconButton, Spinner, Tooltip } from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";

export const ListRefreshButton = (props: any) => {
  const handleFunc = () => {
    props.func();
  };

  return (
    <Tooltip hasArrow label={"Refresh"} bg="blue.600">
      <IconButton
        colorScheme="blue"
        aria-label="Refresh Page"
        icon={<RepeatIcon />}
        mx={4}
        onClick={() => {
          handleFunc();
        }}
        spinner={<Spinner color="white.500" />}
      ></IconButton>
    </Tooltip>
  );
};
