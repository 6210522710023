import { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Checkbox,
  SimpleGrid,
  Radio,
  RadioGroup,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from "@chakra-ui/react";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  NexcoBranchEventAreaInfo,
  IImpEventAreaBaseInfo,
} from "../SystemConst";

type myEventAreaCheckBoxProps = {
  area: string;
  status: IImpEventAreaBaseInfo;
  handleOnChangeEventArea: (res: number) => void;
  handleOnChangeImportansce: (res: number) => void;
};

export const MyEventAreaCheckBox: React.FunctionComponent<
  myEventAreaCheckBoxProps
> = (props) => {
  const [eventAreaValue, setEventAreaValue] = useState(0);
  const [importanceValue, setImportanceValue] = useState("2");

  useEffect(() => {
    //console.info("useEffect:MyEventAreaCheckBox")
    setEventAreaValue(props.status.eventAreaMask);
    setImportanceValue(props.status.importance.toString());
  }, [props]);

  const changeEventAreaChecked = (mask: number, checked: boolean) => {
    let tmpVal = 0;
    if (checked) {
      // ON
      tmpVal = eventAreaValue | mask;
    } else {
      // OFF
      tmpVal = eventAreaValue ^ mask;
    }
    setEventAreaValue(tmpVal);
    props.handleOnChangeEventArea(tmpVal);
    //console.info(tmpVal)
  };
  const changeImportanceSelected = (seletedVal: string) => {
    setImportanceValue(seletedVal);
    props.handleOnChangeImportansce(Number(seletedVal));
    //console.info(seletedVal)
  };

  return (
    <>
      <FormControl id="eventarea" isRequired pt={5}>
        <FormLabel>
          事象発生エリア
          <Popover arrowSize={15} placement="top">
            <PopoverTrigger>
              <IconButton
                boxSize={6}
                colorScheme="white"
                color={"black"}
                aria-label="importanceinfo"
                icon={<QuestionOutlineIcon mt={-1} />}
              />
            </PopoverTrigger>
            <PopoverContent w={"md"}>
              <PopoverArrow bg={"gray.100"} />
              <PopoverCloseButton />
              <PopoverHeader bg={"gray.100"}>
                事象発生エリアとは？
              </PopoverHeader>
              <PopoverBody bg={"gray.100"}>
                <p>
                  情報の対象エリア（どのエリアに対する情報なのか）を選択します
                </p>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </FormLabel>
        <SimpleGrid pl={0} mt={1} columns={3} spacing={1}>
          {NexcoBranchEventAreaInfo[props.area].map((data, index) => (
            <Checkbox
              key={index}
              isChecked={data.mask & eventAreaValue ? true : false}
              onChange={(e) =>
                changeEventAreaChecked(data.mask, e.target.checked)
              }
              value={data.mask}
            >
              {data.lbl}
            </Checkbox>
          ))}
        </SimpleGrid>
      </FormControl>

      <FormControl id="importance" isRequired pt={5}>
        <FormLabel>
          情報重要度
          <Popover arrowSize={15} placement="top">
            <PopoverTrigger>
              <IconButton
                boxSize={6}
                colorScheme="white"
                color={"black"}
                aria-label="importanceinfo"
                icon={<QuestionOutlineIcon mt={-1} />}
              />
            </PopoverTrigger>
            <PopoverContent w={"md"}>
              <PopoverArrow bg={"gray.100"} />
              <PopoverCloseButton />
              <PopoverHeader bg={"gray.100"}>情報重要度とは？</PopoverHeader>
              <PopoverBody bg={"gray.100"}>
                <p>
                  ・重要度１：原則として、他社の媒体・コンテンツに緊急的にお知らせした
                  い場合に選択します
                </p>
                <p>&nbsp;</p>
                <p>
                  ・重要度２：他社の媒体・コンテンツに、切迫してはいないものの確実にお知らせしたい場合に選択します
                </p>
                <p>&nbsp;</p>
                <p>
                  ・重要度３：自社サイトまたは自社エリアにお知らせしたい場合に選択します
                </p>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </FormLabel>
        <RadioGroup onChange={changeImportanceSelected} value={importanceValue}>
          <SimpleGrid pl={0} mt={1} columns={3} spacing={1}>
            <Radio value="1" colorScheme="red">
              重要度１
            </Radio>
            <Radio value="2" colorScheme="yellow">
              重要度２
            </Radio>
            <Radio value="3" colorScheme="green">
              重要度３
            </Radio>
          </SimpleGrid>
        </RadioGroup>
      </FormControl>
    </>
  );
};
