import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ChakraProvider,
  CSSReset,
  Box,
  Alert,
  AlertIcon,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputRightElement,
  Input,
  Button,
  Spinner,
  InputGroup,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { UserContext } from "./AuthContextProvider";
import { SiteTitle } from "./SystemConst";

interface FormInputs {
  email: string;
  password: string;
}

const Login = () => {
  const history = useNavigate();

  // 認証情報を取得
  const [user, setUser] = useContext(UserContext);
  const [signinState, setSigninState] = useState<Boolean>(true); // true: ok or yet / false: Sign in failed
  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
    reset,
  } = useForm<FormInputs>({
    mode: "onSubmit", // or all
  });

  // sleep test
  //const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  // >>> await sleep(2000);

  // 送信時
  const onSubmit = async (data: FormInputs) => {
    // ログイン情報送信
    await axios({
      method: "post",
      url: "/api/users/signup",
      data: { email: data.email, pwd: data.password },
    })
      .then((response) => {
        const res = response.data;
        if (res.id) {
          // 結果がOKの場合、ログイン情報をセットする
          setUser({
            id: res.id,
            email: res.email,
            password: "",
            person: res.person,
            department: res.department, // [EAST | CENTER | WEST | TOKTYO | NAGOYA | WNI]
            role: res.role,

            status: res.status,
            token: res.token,
          });

          setSigninState(true); // Sign Stateus ON
          history("/"); // TOPへ遷移（リダイレクト）
        } else {
          reset();
          setSigninState(false);
          console.log("該当するデータがありません");
        }
      })
      .catch((error) => {
        reset();
        setSigninState(false);
        console.log("通信に失敗しました");
        console.log(error.status);
      });

    if (user) {
      // user 未使用対策...
      console.info("sign in");
    }
  };

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <ChakraProvider>
      <CSSReset />
      <Box mx="auto" mt={"2rem"} mb={"1rem"} maxW="md" fontSize="xl">
        {SiteTitle(process.env.REACT_APP_ENVIRONMENT)}
      </Box>
      <Box
        mt={"1rem"}
        mx="auto"
        bg="gray.100"
        maxW="md"
        shadow="lg"
        p="10px"
        borderRadius="8px"
      >
        {/*
        {formState.isSubmitted ? (
          <Alert status="success" mb={4} >
            <AlertIcon />
              送信完了しました。
          </Alert>
        ) : (
          <>
*/}
        {signinState ? (
          <Alert status="info" mb={4} borderRadius="8px">
            <AlertIcon />
            Sign in
          </Alert>
        ) : (
          <Alert status="error" mb={4} borderRadius="8px">
            <AlertIcon />
            Authentication Failed. Please Try Again.
          </Alert>
        )}

        <form id="signin_form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormControl
            id="email"
            isRequired
            isInvalid={errors.email ? true : false}
            mt={4}
          >
            <FormLabel>Your ID</FormLabel>
            <Input
              placeholder="test@example.com"
              {...register("email", {
                required: true,
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "メールアドレス形式で入力してください",
                },
              })}
              maxLength={128}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            id="password"
            isRequired
            isInvalid={errors.password ? true : false}
            mt={4}
          >
            <FormLabel htmlFor="password">Password</FormLabel>
            <InputGroup>
              <Input
                type={show ? "text" : "password"}
                pr="4.5rem"
                placeholder="Enter password"
                {...register("password", {
                  required: true,
                  minLength: {
                    value: 8,
                    message: "8文字以上入力してください",
                  },
                  maxLength: {
                    value: 16,
                    message: "文字数が超過しています",
                  },
                })}
                maxLength={16}
              />
              <InputRightElement width="4.5rem" top="38px">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>

          <Button
            type="submit"
            colorScheme="blue"
            mt={10}
            mb={5}
            //disabled={!formState.isValid}
            isLoading={formState.isSubmitting}
            spinner={<Spinner color="white.500" />}
          >
            Sign In
          </Button>
        </form>
      </Box>
    </ChakraProvider>
  );
};

export default Login;
