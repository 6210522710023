import { useRequest } from "ahooks";
import {
  Flex,
  Spacer,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  CircularProgress,
  Td,
  TableCaption,
} from "@chakra-ui/react";
import axios from "axios";
import {
  SystemConst,
  ContentsTitleNameHash,
  AreaTagetNames,
  FormatDate,
  IHistoryInterface,
  IContentsTitleHistory,
} from "../SystemConst";
import { ListRefreshButton } from "../partial/ListRefreshButton";
import { MyPagination } from "../partial/MyPagination";
import { useState, useEffect, useRef } from "react";

/**
 *
 * @param props department: (EAST | WEST | CENTER | TOKYO| NAGOYA | WNI)
 * @returns 「タイトル」のDB一覧（登録日時:Desc）
 *
 */
export const ContentsTitleHistoryList: React.VFC<IHistoryInterface> = (
  props,
) => {
  const apiPath = "/api/contents-title-history";
  const showListCount: number = 10;
  const isFirstRender = useRef(true);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [totalPageCnt, setTotalPageCnt] = useState(0);
  const handlePaginate = (page: number) => {
    setCurrentPageNum(page); // <<< これでデータを取得に行く
  };
  async function historyData2(
    pageNum: number,
  ): Promise<IContentsTitleHistory[]> {
    const dataList = await axios({
      method: "GET",
      url: `${apiPath}/allPage/${pageNum}/${showListCount}`,
    })
      .then((responseList) => {
        return responseList.data;
      })
      .catch((error) => {
        console.log(error.status);
        alert("データの取得に失敗しました");
        return [];
      });

    await axios({
      method: "GET",
      url: `${apiPath}/countAster`,
    })
      .then((responseCount) => {
        setTotalPageCnt(responseCount.data);
        return;
      })
      .catch((error) => {
        console.log(error.status);
        alert("全体件数の取得に失敗しました");
        return;
      });

    return dataList;
  }

  const { data, run, loading, refresh } = useRequest<
    IContentsTitleHistory[],
    []
  >(() => historyData2(currentPageNum));

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNum]);

  if (loading) {
    return <CircularProgress isIndeterminate color="green.300" />;
  }

  return (
    <Box overflow="auto" h="100vh" pb={5}>
      <Flex>
        <Spacer />
        <ListRefreshButton
          func={async () => {
            await refresh();
          }}
        />
      </Flex>
      <Table variant="striped" colorScheme="blackAlpha" size="sm">
        <TableCaption>{SystemConst.ContentsTitleTitle}履歴</TableCaption>
        <Thead>
          <Tr>
            <Th>コンテンツ</Th>
            <Th w={400}>タイトル</Th>
            <Th>操作</Th>
            <Th>所属</Th>
            <Th>担当者</Th>
            <Th>実行日時</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            data.map((i: IContentsTitleHistory) => (
              <Tr key={i.id}>
                <Td>{ContentsTitleNameHash[i.idType]}</Td>
                <Td wordBreak={"break-all"} fontSize="xs">
                  {i.title}
                </Td>
                <Td fontSize="xs">{i.operation}</Td>
                <Td fontSize="xs">{AreaTagetNames[i.department]}</Td>
                <Td fontSize="xs">{i.person}</Td>
                <Td fontSize="xs">{FormatDate(i.createdAt)}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <MyPagination
        curr={currentPageNum}
        sum={totalPageCnt}
        per={showListCount}
        onChange={(e) => handlePaginate(e.page)}
      />
    </Box>
  );
};
