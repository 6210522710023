import {
  Button,
  Input,
  InputLeftAddon,
  InputRightElement,
  InputGroup,
  Box,
  useToast,
} from "@chakra-ui/react";
import { IInputContentsTitle, TextEmptyCheck } from "../SystemConst";

export const InputContentsTitle: React.FunctionComponent<{
  param: IInputContentsTitle;
}> = ({ param }) => {
  const toast = useToast();
  const addonText = param.titleText + " タイトル";

  return (
    <Box p={2} w={{ base: 400, md: 500 }}>
      <InputGroup size="sm">
        <InputLeftAddon
          display={{ base: "none", md: "block" }}
          children={addonText}
          fontSize="sm"
          p={1}
        />
        <Input
          p={1}
          size="sm"
          fontSize="16px"
          maxLength={param.inputMaxLen}
          type="text"
          value={param.contentsTitle}
          onChange={(e) => {
            param.func_setContentsTitle(e.target.value);
          }}
        />
        <InputRightElement width="4.0rem" pr={1}>
          <Button
            h="1.75rem"
            size="sm"
            onClick={async () => {
              if (!TextEmptyCheck(param.contentsTitle)) {
                toast({
                  title: "タイトルを入力して下さい",
                  status: "error",
                  position: "top",
                  duration: 2000,
                  isClosable: true,
                });
                return false;
              }

              try {
                await param.func_updateTitle();
                toast({
                  title: "Update Success!",
                  status: "success",
                  position: "top",
                  duration: 1000,
                  isClosable: true,
                });
              } catch (error) {
                toast({
                  title: "Update Failed",
                  status: "error",
                  position: "top",
                  duration: 2000,
                  isClosable: true,
                });
              }
            }}
          >
            {"変更"}
          </Button>
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};
