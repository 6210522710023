import React, { useEffect, useState } from "react";
import { UserType, Department, UserStatus } from "./SystemConst";

export const AuthContext = React.createContext<
  [boolean, React.Dispatch<React.SetStateAction<boolean>>]
>([false, () => {}]);

export const UserContext = React.createContext<
  [User | undefined, React.Dispatch<React.SetStateAction<User | undefined>>]
>([undefined, () => {}]);

type User = {
  id: string;
  email: string;
  password: string;
  person: string;
  department: Department;
  role: UserType;
  status: UserStatus;
  token: string;
};

type AuthProps = {
  //  children: JSX.Element;
  children: React.ReactNode;
};

export const AuthContextProvider: React.FC<AuthProps> = ({ children }) => {
  const [auth, setAuth] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>();

  useEffect(() => {
    setAuth(user?.id ? true : false);
  }, [user]);

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      <UserContext.Provider value={[user, setUser]}>
        {
          // props.children // Mod20230702
          children
        }
      </UserContext.Provider>
    </AuthContext.Provider>
  );
};
