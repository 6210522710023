import { BrowserRouter as Router } from "react-router-dom"; // Mod20230702
import { AuthContextProvider } from "./components/AuthContextProvider";
import AuthenticatedGuard from "./components/AuthenticatedGuard";

function App() {
  return (
    <Router>
      <AuthContextProvider>
        <AuthenticatedGuard />
      </AuthContextProvider>
    </Router>
  );
}

export default App;
