import { useRequest } from "ahooks";
import { Box, Heading, Text, Button, CircularProgress } from "@chakra-ui/react";
import { useState, useContext } from "react";
import { UserContext } from "./AuthContextProvider";
import { SystemConst } from "./SystemConst";

interface IDBUsedSize {
  datname: string;
  size: string;
}

export function SystemManagement() {
  const apiManagementPath = "/api/systemmanagement";
  const [user] = useContext(UserContext);
  if (!user) {
    throw Error("user is not null");
  }
  // アカウントが Admin権限（WNI）以外は処理しない
  if (
    !(
      user.department === SystemConst.AdminDepartment &&
      user.role === SystemConst.UserTypeAdmin
    )
  ) {
    throw Error("user is not ADMIN(WNI)");
  }

  // // 現在のDB使用量を取得する
  const { data, loading, refresh } = useRequest<IDBUsedSize[], []>(
    async (): Promise<IDBUsedSize[]> => {
      const res = await fetch(`${apiManagementPath}/dbUsedSize`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Custom-Auth-Token": user.token,
        },
      })
        .then((x) => {
          return x.json();
        })
        .catch((e) => {
          console.info("response error");
          return [];
        });
      //console.info(res)
      return res;
    },
  );

  // 削除対象の規定日付を取得する
  const { data: rmDate } = useRequest(async (): Promise<string> => {
    const res = await fetch(`${apiManagementPath}/getRemoveTargetDate`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Custom-Auth-Token": user.token,
      },
    })
      .then((x) => {
        return x.json();
      })
      .catch((e) => {
        console.info("response error: getRemoveTargetDate");
        return {};
      });
    return res;
  });
  // 規定期間を経過した履歴データを削除する
  const [remHistBtnState, setRemHistBtnState] = useState<boolean>(false);
  const { run: removeHistory } = useRequest(
    async (): Promise<void> =>
      await fetch(`${apiManagementPath}/removeanyhistory/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Custom-Auth-Token": user.token,
        },
      }).then(() => {
        setRemHistBtnState(false);
        refresh();
      }),
    { manual: true },
  );

  if (loading) {
    return <CircularProgress isIndeterminate color="green.300" />;
  }
  return (
    <Box m={5}>
      <Heading as="h2" size="xl">
        ・履歴一括削除
      </Heading>
      <Box ml={5}>
        <Button
          isLoading={remHistBtnState}
          size="lg"
          colorScheme="green"
          mt="24px"
          onClick={() => {
            setRemHistBtnState(true);
            removeHistory();
          }}
        >
          Cleaning up historical data
        </Button>
        <Text mt={3} fontSize="sm">
          [{rmDate}] 以前の各履歴データを削除します
        </Text>
        <Box m={5} color={"gray.400"}>
          <ul>
            {data && data.map((d: IDBUsedSize, index) => {
              return (d.datname === "logistics%7D" || // prod / stg
                d.datname === "logistics_test" || // dev
                d.datname === "logistics_dev") ? ( // local
                  <li key={index}>
                    {d.datname} [ {d.size} ]
                  </li>
                ) : (
                  ""
                )
              }
            )}
          </ul>
        </Box>
      </Box>
    </Box>
  );
}
