import { FC, useState, useEffect } from "react";
import { Box, Center } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

interface Props {
  images: string[];
  isMultipleUpload: boolean;
  buttonText: string;
  maxFileSize: number;
  maxImagesUpload: number;
  onChange: (imgs: string[]) => void;
}

export const MyImageUpload: FC<Props> = (props) => {
  const [images, setImages] = useState<string[]>([]);
  const isMultiple = props.isMultipleUpload;
  const buttonText = props.buttonText;
  const maxFileSize = props.maxFileSize; // アップロード可能な最大ファイルサイズ
  const maxImagesUpload = isMultiple ? props.maxImagesUpload : 1; // 画像を最大 N枚まで選択・アップロード
  const inputId = Math.random().toString(32).substring(2);

  useEffect(() => {
    setImages(props.images);
  }, [props.images]);

  function readAsDataURL(blob: Blob) {
    return new Promise<string>((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        if (reader.result != null) {
          //console.info("readAsDataURL end")
          resolve(reader.result?.toString());
        } else {
          reject("read error");
        }
      };
      reader.onerror = () => {
        reject(reader.error);
      };

      reader.readAsDataURL(blob);
    });
  }
  async function getFileBase64(files: HTMLInputElement["files"]) {
    if (!files) return [];

    let i = 0;
    const fileCnt = files.length;
    const newImages: string[] = [];

    // validation
    if (fileCnt > maxImagesUpload) {
      alert("選択可能なファイル数を超過しています");
      return newImages;
    }
    while (fileCnt > i) {
      if (files[i].size > maxFileSize) {
        alert("ファイルサイズが超過しているファイルがあります");
        return newImages;
      }
      i++;
    }

    i = 0;
    try {
      while (fileCnt > i) {
        //console.info("fileCnt: " + i)
        let base64Text = await readAsDataURL(files[i]);
        //console.info("new image push")
        newImages.push(base64Text);
        i++;
      }
      setImages(newImages);
    } catch (e) {
      console.info(e);
    } finally {
      return newImages;
    }
  }

  async function fileTypeCheck(filesList: HTMLInputElement["files"]) {
    if (!filesList) return [true];

    function getImageFileType(arrayBuffer: any) {
      if (!arrayBuffer) return false;
      const ba = new Uint8Array(arrayBuffer);
      let headerStr = "";
      let headerHex = "";
      for (let i = 0; i < 10; i++) {
        // 始めの10個分を読む
        headerHex += ba[i].toString(16); // 16進文字列で読む
        headerStr += String.fromCharCode(ba[i]); // 文字列で読む
      }
      let fileType = null;
      if (headerHex.indexOf("ffd8") !== -1) {
        // JPGはヘッダーに「ffd8」を含む
        fileType = "JPG";
      } else if (headerStr.indexOf("PNG") !== -1) {
        // PNGはヘッダーに「PNG」を含む
        fileType = "PNG";
      } else if (headerStr.indexOf("GIF") !== -1) {
        // GIFはヘッダーに「GIF」を含む
        fileType = "GIF";
        //} else if (headerStr.indexOf("BM") != -1) { // BMPはヘッダーに「BM」を含む
        //    fileType = "BMP";
      }
      //console.log("fileType=" + fileType + " headerStr=" + headerStr + " headerHex=" + headerHex);
      return fileType ? true : false;
    }

    return Promise.all(
      Array.from(filesList).map(
        (file) =>
          new Promise<boolean>((resolve, reject) => {
            const reader = new FileReader();

            reader.addEventListener("load", () => {
              //const { result } = reader;
              resolve(getImageFileType(reader.result));
            });

            reader.addEventListener("error", () => {
              reject(reader.error);
            });

            reader.readAsArrayBuffer(file);
          }),
      ),
    );
  }

  const handleOnAddImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist(); // React17以降は不要なコード（あっても何もしないらしい）

    // イメージのファイルタイプをバイナリーレベルで調べる
    const chklist = await fileTypeCheck(e.target.files);
    if (chklist.includes(false)) {
      console.info("images type not support");
      alert("登録できないファイル形式です");
      e.target.value = ""; // Inputをクリア
      return;
    }

    const res = await getFileBase64(e.target.files);
    e.target.value = ""; // Inputをクリア
    props.onChange(res);
  };

  const handleOnRemoveImage = (index: number) => {
    // 選択した画像は削除可能
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    props.onChange(newImages);
  };

  return (
    <>
      <Box borderRadius="lg" borderWidth="1px" p={3}>
        <Center>
          {images.map((image, i) => (
            <Box
              pl={2}
              pr={2}
              key={i}
              style={{
                position: "relative",
                width: "40%",
              }}
            >
              <CloseIcon
                color="red.500"
                w={5}
                h={5}
                style={{
                  position: "absolute",
                  top: 5,
                  right: 9,
                  cursor: "pointer",
                }}
                onClick={() => handleOnRemoveImage(i)}
              />
              <img
                src={image}
                style={{
                  width: "100%",
                }}
                alt={`upload_img_${i}`}
              />
            </Box>
          ))}
        </Center>
        <Center mt={4}>
          <label htmlFor={inputId}>
            <Box
              p={2}
              bg="#efefef"
              borderWidth="1px"
              borderRadius="md"
              cursor="pointer"
            >
              {buttonText}
            </Box>
          </label>
          <input
            id={inputId}
            type="file"
            multiple={isMultiple}
            accept="image/*,.png,.jpg,.jpeg,.gif"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleOnAddImage(e)
            }
            style={{ display: "none" }}
          />
        </Center>
      </Box>
    </>
  );
};
