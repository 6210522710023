import { useRef } from "react";
import { Link } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import "flatpickr/dist/themes/material_green.css";
import { Japanese } from "flatpickr/dist/l10n/ja.js";
import Flatpickr from "react-flatpickr";
import "./customCss.css";

type myDateTimePickerProps = {
  id: string;
  placeholder: string;
  initialvalue: Date | undefined;
  isClear: boolean;
  onChange: (dt: Date) => void;
};

export const MyDateTimePicker = (props: myDateTimePickerProps) => {
  const refComp = useRef<Flatpickr>(null);
  const handleOnChange = (dt: Date[]) => {
    props.onChange(dt[0]);
  };

  const clearDate = () => {
    if (refComp && refComp.current) {
      refComp.current.flatpickr.clear();
    }
  };

  return (
    <>
      <Flatpickr
        id={props.id}
        ref={refComp}
        data-enable-time
        onChange={(date) => {
          handleOnChange(date);
        }}
        className="myDateTimeInput"
        placeholder={props.placeholder}
        options={{
          defaultDate: props.initialvalue,
          dateFormat: "Y-m-d H:i",
          locale: Japanese,
          time_24hr: true,
          minuteIncrement: 1,
          mode: "single",
          //disableMobile: true
        }}
      />
      {props.isClear ? (
        <Link data-clear>
          <DeleteIcon
            border={"1px solid #efefef"}
            borderRadius={"5px"}
            padding={2}
            boxSize={8}
            color={"#8f8f8f"} // '#b8251a'
            onClick={() => {
              clearDate();
            }}
          />
        </Link>
      ) : (
        <></>
      )}
    </>
  );
};
