import { useRequest } from "ahooks";
import {
  Flex,
  Spacer,
  Button,
  CircularProgress,
  Image,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Box,
  VStack,
  StackDivider,
} from "@chakra-ui/react";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "./AuthContextProvider";
import {
  IWinterDrivingTipBody,
  IWinterDrivingTip,
  IWinterDrivingTipWithTitle,
  Operations,
  ContentsTitleKeyHash,
  SystemConst,
  returnStringValue,
  periodFormatText,
  nowDateYMDHM_Szero,
} from "./SystemConst";
import { ListRefreshButton } from "./partial/ListRefreshButton";
import { InputContentsTitle } from "./partial/InputContentsTitle";
import { WinterDrivingTipModalRegForm } from "./partial/WinterDrivingTipModalRegForm";

export function WinterDrivingTip() {
  const apiPath = "/api/winter-driving-tip";
  const [user] = useContext(UserContext);
  if (!user) {
    throw Error("user is not null");
  }
  const signInUser = user;
  // アカウントが Admin権限（WNI）かどうか
  const isAdminDepartment: boolean =
    signInUser.department === SystemConst.AdminDepartment ? true : false;

  const [department] = useState(signInUser.department);
  const [person] = useState(signInUser.person);
  const { data, loading, refresh } = useRequest<IWinterDrivingTipWithTitle, []>(
    (): Promise<IWinterDrivingTipWithTitle> =>
      fetch(`${apiPath}/withtitleNoPeriod`).then((x) => x.json()),
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  /**
   * 新規作成
   */
  const { run: createImgUpload } = useRequest(
    (params: IWinterDrivingTipBody): Promise<void> =>
      fetch(`${apiPath}/withhistory`, {
        method: "POST",
        body: JSON.stringify({
          title: params.title,
          url: params.url,
          thumbnail: params.thumbnail,
          order: params.order,
          fromDate: params.fromDate,
          toDate: params.toDate,
          department: department,
          person: person,
          operation: Operations.CREATE,
        }),
        headers: {
          "Content-Type": "application/json",
          "Custom-Auth-Token": signInUser.token,
        },
      }).then(refresh),
    {
      manual: true,
    },
  );

  /**
   * 更新
   */
  const { run: editImgUpdate } = useRequest(
    (id: number, params: IWinterDrivingTipBody): Promise<void> =>
      fetch(`${apiPath}/withhistory/${id}`, {
        method: "PUT",
        body: JSON.stringify({
          title: params.title,
          url: params.url,
          thumbnail: params.thumbnail,
          order: params.order,
          fromDate: params.fromDate,
          toDate: params.toDate,
          department: department,
          person: person,
          operation: Operations.EDIT,
        }),
        headers: {
          "Content-Type": "application/json",
          "Custom-Auth-Token": signInUser.token,
        },
      }).then(refresh),
    {
      manual: true,
    },
  );

  const requestFunc = async (
    updateId: number,
    params: IWinterDrivingTipBody,
  ) => {
    if (updateId) {
      await editImgUpdate(updateId, params);
    } else {
      await createImgUpload(params);
    }
  };
  /**
   * 削除
   */
  const { run: removeWithHistory } = useRequest(
    (id: number): Promise<void> =>
      fetch(`${apiPath}/withhistory/${id}`, {
        method: "DELETE",
        body: JSON.stringify({
          operation: Operations.DELETE,
          department: department,
          person: person,
        }),
        headers: {
          "Content-Type": "application/json",
          "Custom-Auth-Token": signInUser.token,
        },
      }).then(refresh),
    { manual: true },
  );

  /**
   * コンテンツタイトル編集
   */
  const [contentsTitle, setContentsTitle] = useState("");
  useEffect(() => {
    if (data?.title) {
      setContentsTitle(returnStringValue(data?.title));
    }
  }, [data]);
  const { run: updateTitle } = useRequest(
    (): Promise<void> =>
      fetch(`/api/contents-title/withhistory/${ContentsTitleKeyHash["TIPS"]}`, {
        method: "PUT",
        body: JSON.stringify({
          idType: ContentsTitleKeyHash["TIPS"],
          title: contentsTitle,
          department: user.department,
          person: user.person,
          operation: Operations.EDIT,
        }),
        headers: {
          "Content-Type": "application/json",
          "Custom-Auth-Token": signInUser.token,
        },
      }).then((x) => x.json()),
    {
      manual: true,
    },
  );

  const showContentsTitleElement = () => {
    return isAdminDepartment ? (
      <InputContentsTitle
        param={{
          titleText: "Tips",
          contentsTitle: contentsTitle,
          inputMaxLen: 12,
          func_setContentsTitle: setContentsTitle,
          func_updateTitle: updateTitle,
        }}
      />
    ) : (
      <></>
    );
  };

  /**
   * 登録 / 更新 Modal 初期画像 設定
   */
  const initImgUploadProps: IWinterDrivingTip = {
    id: 0, // Create:0
    title: "",
    url: "",
    thumbnail: "",
    order: 1,

    //    fromDate: new Date(),
    fromDate: nowDateYMDHM_Szero(),

    toDate: undefined,
  };
  const [imgUploadProps, setImgUploadProps] =
    useState<IWinterDrivingTip>(initImgUploadProps);
  const handleOnOpen = (pa: IWinterDrivingTip) => {
    // set local lacale
    pa.fromDate = new Date(pa.fromDate);
    pa.toDate = pa.toDate ? new Date(pa.toDate) : pa.toDate;
    setImgUploadProps(pa);
    //console.info("1. handleOnOpen")
    onOpen();
  };
  /**
   * 掲載期間 Format文字列
   * @param rowData
   * @returns
   */
  const validPriod = (rowData: IWinterDrivingTip) => {
    const res = periodFormatText(rowData.fromDate, rowData.toDate);
    return (
      <>
        <Box color={res.colorState}>{res.fromDateFormat} 〜</Box>
        <Box color={res.colorState}>{res.toDateFormat}</Box>
      </>
    );
  };

  if (loading) {
    return <CircularProgress isIndeterminate color="green.300" />;
  }
  return (
    <div>
      <Flex>
        <Button
          mx={4}
          colorScheme="blue"
          onClick={() => {
            handleOnOpen(initImgUploadProps);
          }}
        >
          作成
        </Button>
        <Spacer />
        {showContentsTitleElement()}
        <Spacer />
        <ListRefreshButton
          func={async () => {
            await refresh();
          }}
        />
      </Flex>
      <Table variant="striped" size="sm">
        <TableCaption>Tips リスト</TableCaption>
        <Thead>
          <Tr>
            <Th p={2}>タイトル / URL</Th>
            <Th p={2}>サムネール</Th>
            <Th p={2}>優先度</Th>
            <Th p={2}>掲載期間</Th>
            <Th p={2}>編集</Th>
            <Th p={2}>削除</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            data.list.map((i: IWinterDrivingTip) => (
              <Tr key={i.id}>
                <Td wordBreak={"break-all"} p={2}>
                  <VStack
                    divider={<StackDivider borderColor="gray.200" />}
                    align="stretch"
                  >
                    <Box>{i.title}</Box>
                    <Box>{i.url}</Box>
                  </VStack>
                </Td>
                <Td p={2}>
                  <Box>
                    <Image src={i.thumbnail} alt="" w={200} />
                  </Box>
                </Td>
                <Td p={2}>{i.order}</Td>
                <Td p={2}>{validPriod(i)}</Td>
                <Td p={2}>
                  <Button
                    size="xs"
                    onClick={() => {
                      handleOnOpen(i);
                    }}
                    colorScheme="teal"
                    variant="ghost"
                  >
                    編集
                  </Button>
                </Td>
                <Td p={2}>
                  <Button
                    size="xs"
                    onClick={async () => {
                      if (window.confirm("本当に削除してもよいですか")) {
                        await removeWithHistory(i.id);
//                        await refresh();
                      }
                    }}
                    colorScheme="teal"
                    variant="ghost"
                  >
                    削除
                  </Button>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <WinterDrivingTipModalRegForm
        param={{
          props: imgUploadProps,
          isOpenFlag: isOpen,
          funcUploadImg: requestFunc,
          funcClose: onClose,
          funcRefresh: refresh,
        }}
      />
    </div>
  );
}
