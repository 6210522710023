import { useRequest } from "ahooks";
import {
  Flex,
  Spacer,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  CircularProgress,
  Td,
  TableCaption,
  VStack,
  StackDivider,
  Icon,
  Select,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import axios from "axios";
import {
  SystemConst,
  AreaHash,
  AreaTagetNames,
  FormatDate,
  IHistoryInterface,
  IAnnouncementHistory,
  FormatDateYMDHM,
  NexcoBranchEventAreaInfo,
  isEventAreaShare,
  isEventAreaDepartment,
  AreaOptionAry,
} from "../SystemConst";
import { ListRefreshButton } from "../partial/ListRefreshButton";
import { MyPagination } from "../partial/MyPagination";
import { useState, useEffect, useRef } from "react";

/**
 *
 * @param props department: (EAST | WEST | CENTER | TOKYO | NAGOYA | WNI)
 * @returns 「お知らせ」のDB一覧（登録日時:Desc）
 *
 */
export const AnnouncemenHistoryList: React.VFC<IHistoryInterface> = (props) => {
  const apiPath = "/api/announcement-history";
  const showListCount: number = 10;
  const isFirstRender = useRef(true);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [totalPageCnt, setTotalPageCnt] = useState(0);
  const handlePaginate = (page: number) => {
    setCurrentPageNum(page); // <<< これでデータを取得に行く
  };

  // アカウントが Admin権限（WNI）かどうか  // Add20230702
  const isAdminDepartment: boolean =
    props.dep === SystemConst.AdminDepartment ? true : false;

    // 一覧の会社絞り込み条件（WNIのみ）：Default "all"
  const selectAll = SystemConst.AdminDepartment
  const [selectedArea, setSelectedArea] = useState(selectAll);
  const selectDepOptArr = AreaOptionAry.map( list => ({...list}))
  selectDepOptArr.unshift({name: '全て', value: selectAll})

  async function historyData2(
    pageNum: number,
  ): Promise<IAnnouncementHistory[]> {
    const tgtDep = isAdminDepartment ? selectedArea : props.dep

    const dataList = await axios({
      method: "GET",
      //url: `${apiPath}/departmentPage/${props.dep}/${pageNum}/${showListCount}`,
      url: `${apiPath}/departmentPage/${tgtDep}/${pageNum}/${showListCount}`,
    })
      .then((responseList) => {
        return responseList.data;
      })
      .catch((error) => {
        console.log(error.status);
        alert("データの取得に失敗しました");
        return [];
      });

    await axios({
      method: "GET",
      //url: `${apiPath}/countAster/${props.dep}`,
      url: `${apiPath}/countAster/${tgtDep}`,
    })
      .then((responseCount) => {
        setTotalPageCnt(responseCount.data);
        return;
      })
      .catch((error) => {
        console.log(error.status);
        alert("全体件数の取得に失敗しました");
        return;
      });

    return dataList;
  }

  // const { data, run, loading, refresh } = useRequest<
  //   IAnnouncementHistory[],
  //   []
  // >(() => historyData2(currentPageNum));
  const { data, run, loading, refresh } = useRequest<IAnnouncementHistory[], []>(
    async () => {
      return await historyData2(currentPageNum)
    }
  );

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }

    if(currentPageNum === 1){
      console.info("useEffect: 1")
      run();
    }else{
      console.info("useEffect: 2")
      setCurrentPageNum(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArea]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNum]);

    // WNI専用：リストの絞り込み（会社）
    const select4AdminDepartment = () => {
      return isAdminDepartment ? (
        <>
          <Select
            value={selectedArea}
            size='xs'
            w={"80px"}
            onChange={(e) => {
              setSelectedArea(e.target.value)
            }}
          >
            {selectDepOptArr.map((area, index) => (
              <option value={area.value} key={index}>
                {area.name}
              </option>
            ))}
          </Select>
        </>
      ):(<></>)
    }
  
  const InfoShareIcon = (rowData: IAnnouncementHistory) => {
    if (rowData.shareStatus === 0) {
      return <></>;
    }
    return <Icon as={InfoIcon} color="gray.400" mr="2" />;
  };

  // NEXCO３社連携対象会社か判定
  const isValidEventAreaShareDepartment = (a: string) => {
    return (isEventAreaShare && (props.dep === SystemConst.AdminDepartment || isEventAreaDepartment(a)));
  };

  //20240510 add
  const createImpEventAreaTxt = (areaStr: string, imp: number, eventArea: number) => {
    if (!isEventAreaShare || !isEventAreaDepartment(areaStr)) {
      return ""
    }

    // 事象発生エリア
    let tmplblAry: string[] = [];
    NexcoBranchEventAreaInfo[areaStr].forEach((item) => {
      if (eventArea & item.mask) {
        tmplblAry.push(item.lbl);
      }
    });

    if(!tmplblAry.length){
      return ""
    }

    return "[" + imp + "] " + tmplblAry.join(", ")
  }

  if (loading) {
    return <CircularProgress isIndeterminate color="green.300" />;
  }

  return (
    <Box overflow="auto" h="100vh" pb={5}>
      <Flex>
        <Spacer />
        <ListRefreshButton
          func={async () => {
            refresh();
          }}
        />
      </Flex>
      <Table variant="striped" colorScheme="blackAlpha" size="sm">
        <TableCaption>{SystemConst.AnnouncementTitle}履歴</TableCaption>
        <Thead>
          <Tr>
            <Th minW={400}>タイトル / URL</Th>
            {/** Mdo20230702 <Th>エリア</Th> */}
            {(isAdminDepartment) ? (
              <Th w={140}>
                <Flex align="center">
                  エリア
                  {select4AdminDepartment()}
                </Flex>
              </Th> 
            ) : (
              <></>
            )}
            {(isValidEventAreaShareDepartment(props.dep)) ? (
              <Th w={200}>[重要度] 事象発生エリア</Th>
             ) : (
              <></>
            )}

            <Th w={170}>掲載期間</Th>
            <Th w={110}>操作</Th>
            <Th w={110}>所属</Th>
            <Th w={110}>担当者</Th>
            <Th w={180}>実行日時</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            data.map((i: IAnnouncementHistory) => (
              <Tr key={i.id}>
                <Td wordBreak={"break-all"} fontSize="xs">
                  <VStack
                    divider={<StackDivider borderColor="gray.200" />}
                    align="stretch"
                  >
                    <Box>
                      {InfoShareIcon(i)}
                      {i.title}
                    </Box>
                    <Box>{i.url}</Box>
                  </VStack>
                </Td>
                {/** Mod20230702*/}
                {isAdminDepartment ? (
                  <Td fontSize="xs">{AreaHash[i.area]}</Td>
                ) : (
                  <></>
                )}

                {(isValidEventAreaShareDepartment(props.dep)) ? (
                  <Td wordBreak={"break-all"} fontSize="xs">
                    {/*[1] 九州*/}
                    {createImpEventAreaTxt(i.area, i.importance, i.event_area)}
                  </Td>
                ) : (
                  <></>
                )}

                <Td fontSize="xs">
                  <VStack
                    divider={<StackDivider borderColor="gray.200" />}
                    align="stretch"
                  >
                    <Box>
                      {i.fromDate ? FormatDateYMDHM(i.fromDate) : ""} 〜
                    </Box>
                    <Box>{i.toDate ? FormatDateYMDHM(i.toDate) : ""}</Box>
                  </VStack>
                </Td>
                <Td fontSize="xs">{i.operation}</Td>
                <Td fontSize="xs">{AreaTagetNames[i.department]}</Td>
                <Td fontSize="xs">{i.person}</Td>
                <Td fontSize="xs">{FormatDate(i.createdAt)}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <MyPagination
        curr={currentPageNum}
        sum={totalPageCnt}
        per={showListCount}
        onChange={(e) => handlePaginate(e.page)}
      />
    </Box>
  );
};
